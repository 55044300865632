body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.event-number {
  /* ... Your styles ... */
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.modal ul {
  list-style: none;
  padding: 0;
}
.modal li {
  margin: 10px 0;
}
.modal button {
  display: block;
  margin-left: auto;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.transaction-dashboard {
  padding: 55px !important;
}

.event-content {
  text-align: center !important;
  font-size: 23px;
}

/* loading bar */
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.loading-spinner {
  background: #fff; /* Background color for the spinner */
  padding: 20px;
  border-radius: 8px;
}

.event-content {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.fade-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(100%);
}

.fc-highlight {
  background-color: #2c9125 !important;
}

.logo-img-splash {
  width: 450px;
  height: auto;
}
.table-container {
  width: 100%;
  text-align: center;
}

div[data-testid="ps-sidebar-container-test-id"] {
  background-color: #121431 !important;
  border: 0px !important;
}

.hambuger-menu {
  position: absolute !important;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: white;
}

.hambuger-menu div svg {
  color: #0b0825;
}

.hambuger-menu a {
  color: #0b0825 !important;
  text-decoration: none;
  margin-left: 5px;
}

.faq-sub-area {
  margin-left: 15px;
}

.privacy-title {
  text-align: center;
  margin-top: 23px;
  margin-bottom: 23px;
}

.faq-sub-answer-1 {
  margin-left: 15px;
}

.alert-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-text {
  text-align: center;
  font-style: italic;
  color: #f6efac;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  margin: 0px;
}

.permissions-header {
  display: inline-flex;
  width: 100%;
}

.permissions-header .permission-th {
  width: 33%;
}

.permissions-title {
  text-align: center;
  border-bottom: 1px white solid;
  margin: 0px;
}

.appusers-table table,
.appusers-table td,
.appusers-table th {
  text-align: center;
}
