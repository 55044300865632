.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
@keyframes highlight {
  0% {
    background-color: rgb(194, 59, 59);
  }
  100% {
    background-color: rgb(23, 94, 202);
  }
}

.highlighted {
  animation: highlight 1s infinite alternate;
  animation-iteration-count: 60;
  transition: background-color 0.5s linear;
}

body{
  background-color: #090a26;
  padding: 0px;
}

.logo-dashboard{
  text-align: center;
}

.device-dashboard{
  color: white;
}

.logo {
  width: 450px;
}

.dashboard-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.online-row {
  background-color: green;
}

/* .offline-row {
  background-color: rgb(216, 22, 22);
} */

tbody tr {
  cursor: pointer;
}

.alert-container tbody tr:hover{
  opacity: 0.5;
}

.dashboard-tabs button[aria-selected='true'] {
  border-top: #f6eeab;
  border-top-width: 3px;
  border-top-style: solid;
  border-radius: 0px;
  background-color: #120d3b !important;
}

.dashboard-tabs button {
  padding: 0px;
  border-radius: 0px;
  background-color: #0b0825 !important;
}

.MuiTabs-indicator {
  width: 0px !important;
}

.MuiTouchRipple-root  {
  width: 0px !important;
}

.add-btn-wrapper {
  display: flex;
  justify-content: end;
  padding: 18px;
}

.css-1212uk9-MuiTabs-indicator, .css-1nu2hs8 {
  border: 1px solid #0b0825 !important;
}

.map-container {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100vh - 200px);
}

/* Geofencing Map */

.control-panel {
  font-size: 14px;
  line-height: 18px;
  width: 284px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  margin: 24px;
  padding: 12px 24px;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  cursor: auto;
  box-sizing: border-box;
  color: black;
}

.control-panel h3 {
  font-size: 1.2em;
  font-weight: 500;
  margin: 8px 0;
}

.control-panel h4 {
  font-weight: 500;
  margin: 8px 0;
}

.control-panel p {
  margin-bottom: 16px;
}

.control-panel .links {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.control-panel .links a {
  font-weight: bold;
  color: #486865;
  font-size: 11px;
}

html[data-theme='dark'] .control-panel {
  background: var(--ifm-background-color);
}

.control-panel input {
  background-color: white;
  color: black;
  outline-color: rgb(16, 16, 16);
}

input.radius-unit::after {
  content: 'Feet';
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust right positioning as needed */
  transform: translateY(-50%);
  color: #666; /* Styling for the 'Feet' text */
}

input:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

html[data-theme='dark'] .gm-style {
  color: var(--ifm-color-black);
}
.autocomplete-container input,
.autocomplete-control {
  box-sizing: border-box;
}

.autocomplete-control {
  margin: 24px;
  background: #fff;
}

.autocomplete-container {
  width: 300px;
}
.autocomplete-container input {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  font-size: 18px;
  background-color: white;
  color: black;
}

.autocomplete-container .custom-list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-container .custom-list-item {
  padding: 8px;
}

.autocomplete-container .custom-list-item:hover {
  background: lightgrey;
  cursor: pointer;
}

.autocomplete-mode {
  margin: 8px 0;
}

.drawing-history {
  margin: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
  height: 27px;
  box-sizing: border-box;
}

.drawing-history button {
  height: 100%;
  background: rgb(255, 255, 255);
  border: 0px;
  margin: 0px;
  cursor: pointer;
  color: rgb(86, 86, 86);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.drawing-history button svg {
  width: 20px;
}
.drawing-history button path {
  fill: rgb(86, 86, 86);
}

.drawing-history button:hover {
  background: rgb(235, 235, 235);
}

.drawing-history button:disabled:hover,
.drawing-history button:disabled {
  background: rgb(255, 255, 255);
  opacity: 0.5;
  cursor: default;
}

:root {
  --ifm-color-primary: #00ade6;
  --ifm-color-primary-dark: #009ccf;
  --ifm-color-primary-darker: #0093c4;
  --ifm-color-primary-darkest: #0079a1;
  --ifm-color-primary-light: #00befd;
  --ifm-color-primary-lighter: #0ac2ff;
  --ifm-color-primary-lightest: #2ccbff;
  --ifm-color-white: #ffffff;
  --ifm-color-gray-200: #f7fafc;
  --ifm-color-gray-300: #ecf2f7;
  --ifm-color-gray-400: #e1e8f0;
  --ifm-color-gray-500: #cad5e0;
  --ifm-color-gray-600: #9eaec0;
  --ifm-color-gray-700: #6f8196;
  --ifm-color-gray-800: #485668;
  --ifm-color-gray-900: #2b3848;
  --ifm-color-black: #19202c;
  --code-font-size: 95%;
}
